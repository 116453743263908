// import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
  },

  {
    path: "/",
    name: "default",
    meta: {
      title: "default",
      auth: true,
    },
    component: () => import("../modules/kanban/pages/main.vue"),
  },
  {
    path: "/reference",
    name: "reference",
    meta: {
      title: "Reference",
      auth: true,
    },
    component: () => import("../modules/reference/pages/main.vue"),
  },
  {
    path: "/reference/users",
    name: "users",
    meta: {
      title: "Users",
      auth: true,
    },
    component: () => import("../modules/reference/users/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "userCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/users/pages/create.vue"),
      },
      {
        path: ":id",
        name: "userUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/users/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/activities",
    name: "activities",
    meta: {
      title: "Activities",
      auth: true,
    },
    component: () => import("../modules/reference/activities/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "activitiesCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/activities/pages/create.vue"),
      },
      {
        path: ":id",
        name: "activitiesUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/activities/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/archive_reasons",
    name: "archive_reasons",
    meta: {
      title: "Archive_reasons",
      auth: true,
    },
    component: () => import("../modules/reference/leadArchiveReason/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "archive_reasonsCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/leadArchiveReason/pages/create.vue"),
      },
      {
        path: ":id",
        name: "archive_reasonsUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/leadArchiveReason/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/service_types",
    name: "service_types",
    meta: {
      title: "Service_types",
      auth: true,
    },
    component: () => import("../modules/reference/serviceTypes/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "service_typesCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/serviceTypes/pages/create.vue"),
      },
      {
        path: ":id",
        name: "service_typesUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/serviceTypes/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/service_estimates",
    name: "service_estimates",
    meta: {
      title: "service_estimates",
      auth: true,
    },
    component: () => import("../modules/reference/estimateService/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "service_estimatesCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/estimateService/pages/create.vue"),
      },
      {
        path: ":id",
        name: "service_estimatesUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/estimateService/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/contracts",
    name: "contracts",
    meta: {
      title: "Contracts",
      auth: true,
    },
    component: () => import("../modules/reference/contracts/pages/main.vue"),
  },
  {
    path: "/reference/contracts/create",
    name: "contractsCreate",
    meta: {
      title: "Create",
      auth: true,
    },
    component: () => import("../modules/reference/contracts/pages/create.vue"),
  },
  {
    path: "/reference/contracts/update/:id",
    name: "contractsUpdate",
    meta: {
      title: "update",
      auth: true,
    },
    component: () => import("../modules/reference/contracts/pages/update.vue"),
  },
  {
    path: "/reference/cargo-categories",
    name: "cargoCategories",
    meta: {
      title: "CargoCategories",
      auth: true,
    },
    component: () => import("../modules/reference/cargoCategories/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "CargoCategoriesCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/cargoCategories/pages/create.vue"),
      },
      {
        path: ":id",
        name: "CargoCategoriesUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/cargoCategories/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/quote_jobs",
    name: "quote_jobs",
    meta: {
      title: "Quote_jobs",
      auth: true,
    },
    component: () => import("../modules/reference/quoteJobs/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "quote_jobsCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/quoteJobs/pages/create.vue"),
      },
      {
        path: ":id",
        name: "quote_jobsUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/quoteJobs/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/container_types",
    name: "container_types",
    meta: {
      title: "Container_types",
      auth: true,
    },
    component: () => import("../modules/reference/containerTypes/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "container_typesCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/containerTypes/pages/create.vue"),
      },
      {
        path: ":id",
        name: "container_typesUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/containerTypes/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/quote_requests",
    name: "quote_requests",
    meta: {
      title: "quote_requests",
      auth: true,
    },
    component: () => import("../modules/reference/quoteRequests/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "quote_requestsCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/quoteRequests/pages/create.vue"),
      },
      {
        path: ":id",
        name: "quote_requestsUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/quoteRequests/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/exchange_rates",
    name: "exchange_rates",
    meta: {
      title: "exchange_rates",
      auth: true,
    },
    component: () => import("../modules/reference/exchangeRates/pages/main.vue"),
  },
  {
    path: "/reference/log_events",
    name: "log_events",
    meta: {
      title: "log_events",
      auth: true,
    },
    component: () => import("../modules/reference/logEvents/pages/main.vue"),
    children: [
      {
        path: ":id",
        name: "log_eventsUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/logEvents/pages/update.vue"),
      },
    ],
  },
  {
    path: "/leads",
    name: "leads",
    meta: {
      title: "Leads",
      auth: true,
    },
    component: () => import("../modules/lead/pages/main.vue"),
  },
  {
    path: "/leads/create",
    name: "leadsCreate",
    meta: {
      title: "Create",
      auth: true,
    },
    component: () => import("../modules/lead/pages/create.vue"),
  },
  {
    path: "/leads/update/:id",
    name: "leadsUpdate",
    meta: {
      title: "Update",
      auth: true,
    },
    component: () => import("../modules/lead/pages/update.vue"),
  },
  {
    path: "/estimate",
    name: "estimate",
    meta: {
      title: "estimate",
      auth: true,
    },
    component: () => import("../modules/estimate/pages/main.vue"),
  },
  {
    path: "/estimate/create",
    name: "estimateCreate",
    meta: {
      title: "Create",
      auth: true,
    },
    component: () => import("../modules/estimate/pages/create.vue"),
  },
  {
    path: "/estimate/update/:id",
    name: "estimateUpdate",
    meta: {
      title: "Update",
      auth: true,
    },
    component: () => import("../modules/estimate/pages/update.vue"),
  },
  {
    path: "/tasks",
    name: "tasks",
    meta: {
      title: "Tasks",
      auth: true,
    },
    component: () => import("../modules/tasks/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "taskCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/tasks/pages/create.vue"),
      },
      {
        path: "update/:id",
        name: "taskUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/tasks/pages/update.vue"),
      },
    ],
  },
  {
    path: "/quotes",
    name: "quotes",
    meta: {
      title: "Quotes",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/main.vue"),
  },
  {
    path: "/quotes/analytics/create",
    name: "quotesAnalyticsCreate",
    meta: {
      title: "CreateAnalytics",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/analytics/create.vue"),
  },
  {
    path: "/quotes/analytics/update/:id",
    name: "quotesAnalyticsUpdate",
    meta: {
      title: "UpdateAnalytics",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/analytics/update.vue"),
  },
  {
    path: "/quotes/logistics_rf/create",
    name: "quotesLogisticsRfCreate",
    meta: {
      title: "CreateLogistics_rf",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/logistics_rf/create.vue"),
  },
  {
    path: "/quotes/logistics_rf/update/:id",
    name: "quotesLogisticsRfUpdate",
    meta: {
      title: "UpdateLogistics_rf",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/logistics_rf/update.vue"),
  },
  {
    path: "/quotes/logistics_international/create",
    name: "quotesLogisticsInternationalCreate",
    meta: {
      title: "CreateLogistics_international",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/logistics_international/create.vue"),
  },
  {
    path: "/quotes/logistics_international/update/:id",
    name: "quotesLogisticsInternationalUpdate",
    meta: {
      title: "UpdateLogistics_international",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/logistics_international/update.vue"),
  },
  {
    path: "/ui",
    name: "ui",
    meta: {
      title: "ui",
      auth: true,
    },
    component: () => import("../views/ui/ui.vue"),
  },
  {
    path: "/quotes_search",
    name: "quotes_search",
    meta: {
      title: "QuotesSearch",
      auth: true,
    },
    component: () => import("../modules/quotesSearch/pages/main.vue"),
  },
  {
    path: "/quotes_search/analytics/create",
    name: "quotes_searchAnalyticsCreate",
    meta: {
      title: "CreateAnalytics",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/analytics/create.vue"),
  },
  {
    path: "/quotes_search/analytics/update/:id",
    name: "quotes_searchAnalyticsUpdate",
    meta: {
      title: "UpdateAnalytics",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/analytics/update.vue"),
  },
  {
    path: "/quotes_search/logistics_rf/create",
    name: "quotes_searchLogisticsRfCreate",
    meta: {
      title: "CreateLogistics_rf",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/logistics_rf/create.vue"),
  },
  {
    path: "/quotes_search/logistics_rf/update/:id",
    name: "quotes_searchLogisticsRfUpdate",
    meta: {
      title: "UpdateLogistics_rf",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/logistics_rf/update.vue"),
  },
  {
    path: "/quotes_search/logistics_international/create",
    name: "quotes_searchLogisticsInternationalCreate",
    meta: {
      title: "CreateLogistics_international",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/logistics_international/create.vue"),
  },
  {
    path: "/quotes_search/logistics_international/update/:id",
    name: "quotes_searchLogisticsInternationalUpdate",
    meta: {
      title: "UpdateLogistics_international",
      auth: true,
    },
    component: () => import("../modules/quotes/pages/logistics_international/update.vue"),
  },
];
