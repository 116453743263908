import { createRouter, createWebHistory } from "vue-router";
import { useAuth } from "@/state/modules/auth";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const auth = useAuth();
  if (to.meta.auth && !auth.isAuth) {
    next({ path: "/login" });
  } else if (!to.meta.auth && auth.isAuth) {
    next({ path: "/" });
  } else {
    next();
  }
});

export default router;
